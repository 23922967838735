export default {
  BUTTON_PRIMARY_CLASS: 'button',
  BUTTON_SECONDARY_CLASS: 'button button--outline',
  FACET_BUTTON_CLASS: '',

  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  RELATED_PRODUCT_LIST_CLASS: 'product-list',
  RELATED_PRODUCT_TITLE_CLASS: 'h2',
  YOU_MAY_ALSO_LIKE: 'You might also like',

  GARAGE_SIZE: '<span class="garage-size count-bubble" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: '',
};
