//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-776:_8392,_9364,_3192,_2063,_7772,_9120,_9672,_3475;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-776')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-776', "_8392,_9364,_3192,_2063,_7772,_9120,_9672,_3475");
        }
      }catch (ex) {
        console.error(ex);
      }