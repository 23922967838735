//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-776:_9160,_3596,_5032,_9008,_3676,_8588,_1772,_7964;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-776')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-776', "_9160,_3596,_5032,_9008,_3676,_8588,_1772,_7964");
        }
      }catch (ex) {
        console.error(ex);
      }